import { indexBy, prop } from 'ramda'
import { useSelector } from 'react-redux'
import React, { createContext, useEffect, useState } from 'react'
import baseCustomization from './data'
import { getHome, getProfile } from '~resources/customization'

export const CustomizationContext = createContext(baseCustomization)

export const CustomizationProvider = ({ children }) => {
  const selectedProfile = useSelector((state) => state.user.selectedProfile)
  const [customization, setCustomization] = useState(baseCustomization)

  useEffect(() => {
    getHome().then(([error, data]) => {
      if (data) {
        setCustomization((currentState) => ({ ...currentState, ...data }))
      }

      if (error) {
        console.error(error)
      }
    })
  }, [])

  useEffect(() => {
    if (selectedProfile) {
      getProfile().then(([error, data]) => {
        if (data) {
          const { resources, ...profileData } = data
          const exams = indexBy(prop('resource'), resources)

          setCustomization((currentState) => ({
            ...currentState,
            ...profileData,
            ...exams,
          }))
        }

        if (error && error.status !== 401) {
          console.error(error)
        }
      })
    }
  }, [selectedProfile])

  return (
    <CustomizationContext.Provider value={customization}>
      {children}
    </CustomizationContext.Provider>
  )
}

export const CustomizationConsumer = CustomizationContext.Consumer

export default CustomizationContext
