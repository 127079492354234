import React from 'react'
import { styled } from '@mui/material/styles'
import ReactApexChart from 'react-apexcharts'
import { formatDecimalNumber } from '~helpers/formatting/numbers'

const Container = styled('div')`
  width: ${({ width }) => width + 'px' || 'auto'};
`

export const ColumnChart = ({
  name = 'column-chart',
  title = '',
  series,
  height,
  width,
  percentage = false,
  tickInterval,
  gridLine = false,
  labelColumns = false,
  bgColor = '',
  maximum,
  minimun = 0,
}) => {
  function createDataArray(dataArray) {
    const result = []

    dataArray.forEach((item) => {
      let color = item.color
      item.data.forEach((dataItem) => {
        const yValues = Array.isArray(dataItem.y) ? dataItem.y : [dataItem.y]
        yValues.forEach((yValue) => {
          color = dataItem?.color ? dataItem.color : color
          result.push({
            color: color,
            y: yValue,
            name: dataItem.name?.replace(/<[^>]*>.*?<\/[^>]*>/g, '') || '',
          })
        })
      })
    })

    return result
  }

  const formattedDataArray = createDataArray(series)

  const formatter = function (val) {
    return `${formatDecimalNumber(2)(val)}${percentage ? '%' : ''}`
  }

  const options = {
    series: [
      {
        data: formattedDataArray.map((item) => item.y),
        name: '',
      },
    ],
    options: {
      chart: {
        toolbar: {
          show: false,
        },
        width: width,
        type: 'bar',
        background: bgColor,
      },
      plotOptions: {
        bar: {
          columnWidth: '50%',
          distributed: true,
        },
      },
      labels: {
        show: false,
      },
      grid: {
        show: gridLine,
      },
      fill: {
        opacity: 1,
        colors: formattedDataArray.map((item) => item.color),
      },
      dataLabels: {
        enabled: false,
      },
      legend: {
        show: false,
      },
      tooltip: {
        enabled: true,
        y: {
          formatter: function (val) {
            return formatter(val)
          },
          title: {
            formatter: function (seriesName) {
              return seriesName
            },
          },
        },
      },
      xaxis: {
        type: 'category',
        categories: formattedDataArray.map((item) => item.name),
        labels: {
          rotate: -45,
          rotateAlways: false,
          hideOverlappingLabels: false,
          style: {
            fontSize: '12px',
          },
        },
      },
      yaxis: {
        show: labelColumns,
        max: maximum,
        min: minimun,
        stepSize: tickInterval,
        formatter,
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        labels: {
          show: true,
          formatter,
        },
      },
      title: {
        text: title,
      },
    },
  }

  return (
    <Container data-testid={name} width={width}>
      <div id="chart">
        <ReactApexChart {...options} type="bar" height={height} />
      </div>
      <div id="html-dist" />
    </Container>
  )
}
