import React from 'react'
import ReactApexChart from 'react-apexcharts'
import { formatDecimalPercent } from '~helpers/formatting/numbers'

export const GaugeChart = ({
  name = 'gauge-chart',
  height = 150,
  width = 80,
  percentage,
  color,
  titleColor,
  backgroundColor = '#C2C2C2',
  value = 0,
  min = 0,
  max = 1,
}) => {
  const series = [(value / max) * 100]

  const formatter = function () {
    return value === 0
      ? ' ! '
      : percentage
        ? formatDecimalPercent(0)(value / max)
        : value
  }

  const options = {
    chart: {
      height: height,
      width: width,
      type: 'radialBar',
    },
    yaxis: {
      formatter,
      min,
      max,
    },
    plotOptions: {
      radialBar: {
        hollow: {
          size: '50%',
          color: 'transparent',
          background: 'transparent',
        },
        track: {
          background: backgroundColor,
        },
        dataLabels: {
          show: true,
          name: {
            show: true,
            fontSize: '26px',
            fontWeight: 400,
            color: titleColor || '#000',
            offsetY: 10,
          },
          value: {
            show: false,
          },
        },
      },
    },
    colors: [color],
    labels: [formatter()],
  }

  return (
    <div data-testid={name} style={{ paddingBottom: '0px' }}>
      <div id="chart" style={{ paddingBottom: '0px' }}>
        <ReactApexChart options={options} series={series} type="radialBar" />
      </div>
      <div id="html-dist"></div>
    </div>
  )
}
