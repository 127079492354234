import React from 'react'
import {
  Skeleton,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import { InsightContainer } from '~primitives/InsightsContainer'
import { InsightBox } from '~primitives/InsightsContainer/Box'
import { GaugeChart } from '~primitives/Charts/Gauge'
import { formatDecimalPercent } from '~helpers/formatting/numbers'

const INSIGHT_BOX_STYLES = {
  width: { xs: '100%', md: 215 },
  height: { xs: 50, md: 120 },
}

const formatParticipation = formatDecimalPercent(0)

export const Insights = ({
  averageHitRate,
  answeredRate,
  answeredCount,
  studentsCount,
  notStartedCount,
  loading,
}) => {
  const { palette, breakpoints } = useTheme()
  const isMobile = useMediaQuery(breakpoints.down('md'))
  const isDesktop = useMediaQuery(breakpoints.up('xl'))
  const { warning, success, error, grey, action } = palette

  const chartColor = !answeredRate
    ? grey[0]
    : answeredRate < 33
      ? error.main
      : answeredRate
        ? warning.main
        : success.main

  return (
    <InsightContainer>
      <Stack
        direction="row"
        flexWrap={isMobile ? 'wrap' : 'nowrap'}
        gap={{ xs: 0.25, md: 2 }}
        textAlign="center"
      >
        <InsightBox
          bgcolor="resultables_school"
          title={
            <Typography fontSize={13} color="grey.0">
              Média de nota da turma
            </Typography>
          }
          value={
            <Typography fontSize={30} pb={{ xs: 0, md: 2 }} color="grey.0">
              {loading ? <Skeleton /> : averageHitRate}
            </Typography>
          }
          {...INSIGHT_BOX_STYLES}
        />

        <InsightBox
          title="Participação dos alunos"
          value={
            isDesktop ? (
              <GaugeChart
                percentage
                name="student-participation-gauge"
                height={80}
                value={answeredRate ?? 0}
                color={chartColor}
                titleColor={chartColor}
                backgroundColor={grey[0]}
              />
            ) : (
              <Typography
                fontSize={30}
                pb={{ xs: 0, md: 2 }}
                color={chartColor}
              >
                {formatParticipation(answeredRate)}
              </Typography>
            )
          }
          {...INSIGHT_BOX_STYLES}
        />

        <InsightBox
          title="Total de alunos"
          value={
            <Typography fontSize={30} pb={{ xs: 0, md: 2 }}>
              {loading ? <Skeleton /> : studentsCount}
            </Typography>
          }
          {...INSIGHT_BOX_STYLES}
        />

        <InsightBox
          icon={
            isDesktop && { name: 'accountMember', color: palette.success.main }
          }
          title="Alunos presentes"
          value={
            <Typography fontSize={30} pb={{ xs: 0, md: 2 }}>
              {loading ? <Skeleton /> : answeredCount}
            </Typography>
          }
          {...INSIGHT_BOX_STYLES}
        />

        <InsightBox
          icon={isDesktop && { name: 'accountMember', color: action.disabled }}
          title="Alunos ausentes"
          value={
            <Typography fontSize={30} pb={{ xs: 0, md: 2 }}>
              {loading ? <Skeleton /> : notStartedCount}
            </Typography>
          }
          {...INSIGHT_BOX_STYLES}
        />
      </Stack>
    </InsightContainer>
  )
}
